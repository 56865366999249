

// HOVER
//////////////////////////////////////////////////////////

.btn-base__010 a,
.btn-base__020 a,
.btn-base__030 a,
.btn-base__040 a,
.btn-base__050 a,
.btn-base__060 a,
.btn-base__070 a,
.btn-base__080,
.btn-base__090,
.btn-base__100 a,
.list-base__100 a,
.col-base__050 a,
.footer-common__listbanner li img,
.article-list__item,
.nav-category__item a,
.col-homepoint__subject a,
.btn-base__pagetop a,
.list-base__080 li a,
.list-base__100 li a,
.header-common__pullItem,
.header-common__title a,
.header-common__titlesub a
{
	opacity: 1;
	transition: background-color .5s, border-radius .5s,opacity .5s;
}


.btn-base__010 a:hover,
.btn-base__020 a:hover,
.btn-base__030 a:hover,
.btn-base__040 a:hover,
.btn-base__050 a:hover,
.btn-base__060 a:hover,
.btn-base__070 a:hover,
.btn-base__080:hover,
.btn-base__090:hover,
.btn-base__100 a:hover,
.col-base__050 a:hover,
.footer-common__listbanner li img:hover,
.article-list__item:hover,
.nav-category__item a:hover,
.col-homepoint__subject a:hover,
.btn-base__pagetop a:hover,
.list-base__080 li a:hover,
.list-base__100 li a:hover,
.header-common__pullItem:hover,
.header-common__title a:hover,
.header-common__titlesub a:hover,
.col-careItem a:hover
{
	opacity: .6;
	transition: background-color .3s, border-radius .3s,opacity .3s;
}


// ADJUST
//////////////////////////////////////////////////////////

// align
//--------------------------------------------------------
.c-center {
	text-align: center;
}

.c-right {
	text-align: right;
}

// weight
//--------------------------------------------------------
.c-bold {
	font-weight: 900;
}

// hide
//--------------------------------------------------------
.c-hide {
	display: none;
}

// text
//--------------------------------------------------------
.c-text {
	&__S {
		font-size: 12px!important;
		@include media-breakpoint-down(xs) {
			font-size: 12px!important;
		}
	}
	&__XL {
		font-size: 38px!important;
		@include media-breakpoint-down(xs) {
			font-size: 24px!important;
		}
	}
	&__XXL {
		font-size: 45px;
	}
}

// NEW MARK
//--------------------------------------------------------
.c-new {
	&::after {
		content:"NEW";
		display: block;
		position: absolute;
		top: -15px;
		right: -15px;
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 12px;
		font-weight: normal;
		text-align: center;
		border-radius: 100%;
		color: #FFFFFF;
		background-color: #ea68a2;

	}
}


// text
//--------------------------------------------------------

.a-view {
	&__pc {
		display: block;
		@include media-breakpoint-down(xs) {
			display: none;
		}
	}
	&__sp {
		display: none;
		@include media-breakpoint-down(xs) {
			display: block;
		}
	}
}
