
//////////////////////////////////////////////////////////
// Layout
//////////////////////////////////////////////////////////



.col-phone{
	display: none;
	@include media-breakpoint-down(xs) {
		display: block;
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		border:4px solid #1379ec;
		width: 100%;
		z-index: 10000010;
		padding: 6px;
		text-align: center;

	}
	.text1 {
		font-size: 20px;
		margin-bottom: 0;
		line-height: 1.2;
		color: #16418f;
	}
	.text2 {
		font-size: 16px;
		margin-bottom: 0px;
		line-height: 1;
		color: #000000;
	}
	.phone {
		font-size: 24px;
		margin-bottom: 0;
		color: #e6552e;
		font-weight: bold;
		line-height: 1.2;
	}
}

.header {
	&-home {
		width: 100%;
		@include media-breakpoint-down(xs) {
		}
		.t1 {
			width: 100%;
			height: auto;
		}
		img {
			width: 100%;
			height: auto;

		}
	}
}



// sec-base
//////////////////////////////////////////////////////////
.sec-base {
	&__010 {
		padding: 60px 0;
		@include media-breakpoint-down(xs) {
			padding: 10px 10px;
		}
		.inner {
			width: 980px;
			margin: 0 auto;
			@include media-breakpoint-down(xs) {
				width: auto;
			}
		}
	}
}

// sec-cnt
//////////////////////////////////////////////////////////

.sec-cnt {
	&__banner {
		@include media-breakpoint-down(xs) {
			// padding-bottom: 100px;
		}
		&:last-of-type {
			padding-bottom: 100px;
		}
	}
	&__010 {
		background-color: #1379ec;
	}
	&__020 {
		background-color: #fffcea;
	}
	&__030 {
		background: url(../images/bg_0001.png) repeat 0 0;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			display: block;
			box-sizing: border-box;
			width: 50px;
			height: 50px;
			left: 0;
			right: 0;
			bottom: -130px;
			margin: auto;
			border: 70px solid transparent;
			border-left: 50px solid #FFF;
			-webkit-transform: rotate(90deg);
    		transform: rotate(90deg);
		}
	}
	&__040 {
		background-color: #1379ec;
	}
	&__050 {
		background-color: #ffffff;
	}
	&__060 {
		background: url(../images/bg_0002.png) no-repeat 0 50%;
		background-size: 100%;
		.inner {
			position: relative;
		}
	}
	&__070 {
		background: url(../images/bg_0003.png) no-repeat 0 50%;
		background-size: 100%;
		.inner {
			position: relative;
		}
	}
	&__080 {
		background: url(../images/bg_0002.png) no-repeat 0 50%;
		background-size: 100%;
		.inner {
			position: relative;
		}
	}
	&__090 {
		background-color: #ffffff;
	}
	&__100 {
		background-color: #c9e1fc;
		padding-top: 0;
		@include media-breakpoint-down(xs) {
			padding-top: 60px;
		}
	}
	&__110 {
		background-color: #c2ebf8;
		padding-top: 0;
		@include media-breakpoint-down(xs) {
			padding-top: 60px;
		}
	}
	&__120 {
	}
	&__130 {
	}
	&__140 {
		background: url(../images/bg_0004.png) repeat 0 0;
	}
}

// ttl-base
//////////////////////////////////////////////////////////
.ttl-base {
	&__010 {
		font-size: 24px;
		color: #fbb308;
		text-align: center;
		background: url(../images/bg_0005.png) repeat-x 0 bottom;
		padding-bottom: 10px;
		margin-bottom: 20px;
		font-weight: bold;
		.sub {
			font-size: 40px;
			margin-left: 8px;
		}
	}
	&__020 {
		font-size: 32px;
		color: #09408c;
		font-weight: bold;
		.sub {
			font-size: 40px;
			color: #b19336;
			@include media-breakpoint-down(xs) {
				font-size: 26px;
			}
		}
		.sub2 {
			font-size: 40px;
			@include media-breakpoint-down(xs) {
				font-size: 26px;
			}
		}
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
	}
	&__030 {
		font-size: 32px;
		font-weight: bold;
		background: #1173e2;
		background: -moz-linear-gradient(top,  #1173e2 0%, #0d4d96 100%);
		background: -webkit-linear-gradient(top,  #1173e2 0%,#0d4d96 100%);
		background: linear-gradient(to bottom,  #1173e2 0%,#0d4d96 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1173e2', endColorstr='#0d4d96',GradientType=0 );
		border-radius: 40px;
		color: #ffffff;
		text-align: center;
		padding:10px 0;
		margin-bottom: 20px;
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}

	}
	&__040 {
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
		color: #ffffff;
		.sub {
			font-size: 28px;
			color: #fffd64;
		}
	}
	&__050 {
		font-size: 24px;
		font-weight: bold;
		color: #666666;
		margin-bottom: 60px;
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
		.sub {
			background-color: #fbb308;
			display: inline-block;
			padding:0 6px;
		}
	}
	&__060 {
		font-size: 24px;
		font-weight: bold;
		background-color: #1379ec;
		text-align: center;
		padding: 20px 10px;
		margin-bottom: 40px;
		color: #ffffff;
		@include media-breakpoint-down(xs) {
			font-size: 18px;
		}
	}
	&__070 {
		background-color: #1379ec;
		text-align: center;
		padding: 20px 0;
		margin-bottom: 40px;
		color: #ffffff;
		font-weight: bold;
	}
	&__080 {
		text-align: center;
		padding: 20px 0;
		color: #09408c;
		font-size: 24px;
		font-weight: bold;
	}
	&__090 {
		background-color: #1379ec;
		padding: 20px 0;
		text-align: center;
		color: #ffffff;
		font-size: 24px;
		font-weight: bold;
		border-radius: 10px 10px 0 0;
		margin-bottom: 30px;
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
		.sub {
			color: #fffd64;
			font-size: 30px;
		}
	}
	&__100 {
	}
	&__110 {
		padding:20px 0 10px 0;
		font-weight: bold;
		color: #666666;
		font-size: 24px;
		.sub {
			font-size: 20px;
		}
	}
	&__120 {
		font-weight: bold;
		color: #666666;
		font-size: 28px;
		margin-bottom:30px;
		text-align: center;
		position: relative;
		span {
			position: absolute;
			z-index: 1000010;
			left: 0;
			top: 0;
			width: 560px;
			@include media-breakpoint-down(xs) {
				width: auto;
			}
		}
		&::before {
			content: "";
			display: block;
			width: 154px;
			height: 154px;
			background: url(../images/cnt_0009.png) no-repeat 0 0;
			position: absolute;
			top: -95px;
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 90px;
			background: url(../images/cnt_0010.png) no-repeat 50% 0;
			position: absolute;
			top: 25px;
		}
	}
	&__130 {
	}
	&__140 {
		color: #09408c;
		text-align: center;
		background-color: #f3f3f3;
		padding:20px 0;
		font-weight: bold;
	}
	&__150 {
		text-align: center;
		padding: 20px 0;
		color: #09408c;
		font-size: 24px;
		font-weight: bold;
		background: url(../images/bg_0008.png) no-repeat center 30%;
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
		&::after {
			content: "";
			display: block;
			background: url(../images/bg_0009.png) repeat-x 0 0;
			width: 100%;
			height: 4px;
			margin-top: 20px;
		}
	}
	&__160 {
		display: inline-block;
		background-color: #1379ec;
		padding:10px;
		color: #fdff6e;
		font-weight: bold;
		font-size: 30px;
		margin-bottom: 0;
		border-radius: 10px 10px 0 0;
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
		.sub {
			font-size: 40px;
			@include media-breakpoint-down(xs) {
				font-size: 28px;
			}
		}
	}
	&__170 {
		color: #1379ec;
		font-weight: bold;
		font-size: 24px;
		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
		.sub {
			display: block;
			font-size: 30px;
			@include media-breakpoint-down(xs) {
				font-size: 24px;
			}
		}
		em {
			color: #fc9113;
		}
	}
}

// list-base
//////////////////////////////////////////////////////////
.list {
	&-base {
		&__010 {
			display: flex;
			@include media-breakpoint-down(xs) {
				display: block;
			}
		}
		&__020 {
		}
		&__030 {
		}
		&__040 {
			li {
				position: relative;
				&::after {
					content: "";
					position: absolute;
					display: block;
					box-sizing: border-box;
					width: 30px;
					height: 30px;
					left: 0;
					right: 0;
					bottom: -120px;
					margin: auto;
					border: 70px solid transparent;
					border-left: 30px solid #144c97;
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
				&:last-child {
					&::after {
						display: none;
					}
				}
			}
		}
		&__050 {
			li {
				text-indent: -1em;
				margin-left: 1em;
			}
		}
	}
	&-item {
		&__010 {
			width: 320px;
			padding:20px;
			background-color: #ffffff;
			margin-right: 10px;
			border-radius: 10px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 20px;
				margin-right: 0;
				width: auto;
			}
			&:last-child {
				margin-right: 0;

			}

		}
		&__020 {
			font-size: 20px;
			color: #666666;
			margin-bottom: 20px;
			font-weight: bold;
			@include media-breakpoint-down(xs) {
				font-size: 18px;
			}
			&::before {
				content: "";
				display: inline-block;
				background: url(../images/bg_0006.png) no-repeat 0 50%;
				width: 50px;
				height: 50px;
				background-size: 100%;
				margin-right: 16px;
				position: relative;
				top: 14px;
			}
		}
		&__030 {
			border: 3px solid #1379ec;
			margin-bottom: 20px;
			padding:10px;
			font-size: 20px;
			@include media-breakpoint-down(xs) {
				font-size: 18px;
			}
			.sub {
				background: url(../images/bg_0007.png) no-repeat 0 0;
				display: inline-block;
				width: 38px;
				height: 38px;
				text-align: center;
				line-height: 38px;
				font-size: 30px;
				font-weight: bold;
				color: #FFF;
				margin-right: 16px;;
			}
		}
	}
}

// text-base
//////////////////////////////////////////////////////////
.text {
	&-base {
		&__010 {
			.attention {
				color: #d70000;
			}
		}
		&__020 {
			color: #2e62af;
			font-size: 20px;
			@include media-breakpoint-down(xs) {
				font-size: 18px;
			}
		}
		&__030 {
			text-align: right;
		}
	}
}

// table-base
//////////////////////////////////////////////////////////
.table {
	&-base {
		&__010 {
			width: 100%;;
			border: 2px solid #666666;
			margin-bottom: 40px;
			.t-center {
				text-align: center;
			}
			th {
				border-bottom: 1px solid #666666;
				border-right: 1px solid #666666;
				padding:20px;
				font-weight: bold;
				@include media-breakpoint-down(xs) {
					font-size:80%;
					padding:8px;
				}
			}
			td {
				border-bottom: 1px solid #666666;
				border-right: 1px solid #666666;
				padding:20px;
				@include media-breakpoint-down(xs) {
					font-size:80%!important;
					padding:8px;
				}
				.price {
					color: #fc9113;
					font-size:20px;
					font-weight: bold;
					&.l-txt {
						font-size: 32px;;
						@include media-breakpoint-down(xs) {
							font-size: 18px;;
						}
					}
				}
				.yen {
					color: #fc9113;
					font-weight: bold;
				}
				.price2 {
					color: #9cc6d3;
					font-size:20px;
					font-weight: bold;
					&.l-txt {
						font-size: 26px;;
						@include media-breakpoint-down(xs) {
							font-size: 18px;;
						}
					}
				}
				.yen2 {
					color: #9cc6d3;
					font-weight: bold;
				}
				.baloon__010 {
					position: relative;

					&::before {
						content: "";
						display: inline-block;
						background: url(../images/img_baloon_0001.png) no-repeat 0 0;
						width: 190px;
						height: 65px;
						position: absolute;
						top: -78px;
						right: -104px;
						@include media-breakpoint-down(xs) {
							top: -120px;
						}

					}
				}
			}
		}
	}
	&-th {
		&-base {
			&__000 {
				background-color: #f0f0f0;
				color: #666666;
				text-align: center;
				font-weight: bold;
			}
			&__010 {
				background-color: #f1fcff;
				color: #666666;
				text-align: center;
			}
			&__020 {
				background-color: #fc9113;
				color: #ffffff;
				text-align: center;
			}
			&__030 {
			}
			&__040 {
				background-color: #f0f0f0;
				.must {
					color: #d70000;
				}
			}
		}
	}
	&-td {
		&-base {
			&__010 {
				background-color: #f0f0f0;
				color: #666666;
				text-align: center;
				font-weight: bold;
			}
			&__020 {
				background-color: #fff5e9;
				color: #666666;
				// text-align: center;
			}
			&__030 {
				background-color: #ffffff;
				color: #666666;
				// text-align: center;
			}
			&__040 {
				label {
					margin-right: 0.5em;
				}
				.form-text {
					width: 100%;
				}
				.form-check {
					margin-right:1em;
				}

			}
		}
	}
}

// define-base
//////////////////////////////////////////////////////////
.define {
	&-base {
		&__010 {
			background-color: #FFF;
			padding:30px 20px;
			border-radius: 10px;
		}
		&__020 {
			border: 3px solid #144c97;
			border-radius: 10px;
			margin-bottom: 60px!important;
			position: relative;
			display: flex;
			align-items: center;
			// justify-content: center;
			overflow: hidden;
			background-color: #ffffff;

			&.islast {
				&::after {
					display: none;
				}
			}
		}
	}
	&-ttl {
		&__010 {
			font-size: 24px;
			color: #09408c;
			font-weight:bold;
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				font-size: 16px;
				width: auto;
			}
		}
		&__020 {
			background-color: #77b7ff;
			padding:30px 20px;
			color: #ffffff;
			font-size: 20px;
			width: 25%;
			text-align: center;
			font-weight: bold;
			@include media-breakpoint-down(xs) {
				font-size: 16px;
				width: auto;
			}

		}
		&__030 {
			background-color: #ffaf51;
			padding:30px 20px;
			color: #ffffff;
			font-size: 20px;
			width: 130px;
			text-align: center;
			font-weight: bold;
			width: 25%;
			height: 100%;
			@include media-breakpoint-down(xs) {
				font-size: 16px;
				width: auto;
			}
		}
	}
	&-text {
		&__010 {

		}
		&__020 {
			background-color: #ffffff;
			color: #77b7ff;
			font-weight: bold;
			font-size: 20px;
			width: 75%;
			margin: 0;
			padding-left: 10px;
			padding-right: 10px;
			text-align: left;
			@include media-breakpoint-down(xs) {
				font-size: 14px;
			}
		}
		&__030 {
			background-color: #ffffff;
			color: #ffaf51;
			font-weight: bold;
			font-size: 20px;
			width: 75%;
			margin: 0;
			padding-left: 10px;
			padding-right: 10px;
			text-align: left;
			@include media-breakpoint-down(xs) {
				font-size: 14px;
			}
		}
	}
}

// col-base
//////////////////////////////////////////////////////////
.col {
	&-base {
		&__010 {
			padding:40px;
			background-color: #ffffff;
		}
		&__020 {
			background: url(../images/cnt_0011.png) no-repeat right 0;
			padding-right: 400px;
			padding-left: 20px;
			min-height: 550px;
			box-shadow: 0 10px 10px rgba(0,0,0,0.1);
			@include media-breakpoint-down(xs) {
				padding-right: 20px;
				padding-bottom: 200px;
				min-height: 100px;
				background: url(../images/cnt_0011.png) no-repeat 180% 100%;
				background-size: 120%;
			}
			.text-base__010 {
				padding-top: 80px;
			}
		}
		&__030 {
			width: 760px;
			@include media-breakpoint-down(xs) {
				width: auto;
			}
			.details {
				border-top: 10px solid #1379ec;
				background:#ffffff url(../images/bg_0001.png) repeat 0 0;
				padding:60px;
				@include media-breakpoint-down(xs) {
					padding:20px;
					margin-bottom: 20px;
				}
			}
		}
	}
}


// img-base
//////////////////////////////////////////////////////////
.img {
	&-base {
		&__010 {
			img {
				width: 100%;
				height: auto;
			}
		}
		&__020 {
			float: right;
			padding:0 0 20px 20px;
		}
		&__030 {
			img {
				width: 100%;
				height: auto;
			}
			&.bnr_set {
				padding: 40px;
			}
		}
		&__040 {
			img {
				@include media-breakpoint-down(xs) {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}


// btn-base
//////////////////////////////////////////////////////////
.btn {
	&-base {
		&__010 {
			background-color: #ffaf51;
			border-radius: 60px;
			width:980px;
			text-align: center;
			font-weight: bold;
			font-size: 30px;
			padding:10px;
			position: relative;
			@include media-breakpoint-down(xs) {
				width: auto;
				font-size: 14px;
			}
			.sub {
				font-size: 36px;
				display: block;
				@include media-breakpoint-down(xs) {
					font-size: 20px;
				}
			}
			a {
				color: #ffffff;
				display: block;
				&:hover {
					text-decoration: none;
				}
			}
			&::after {
				content: "";
				position: absolute;
				right: 50px;
				top: 40%;
				width: 20px;
				height: 20px;
				border-top: 4px solid #ffffff;
				border-right: 4px solid #ffffff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
	&-submit {
		width: 340px;
		margin: 0 auto;
		background-color: #ff9783;
		border-radius: 20px;
		border:0 ;
		color: #FFF;
		padding:20px 0
	}
}



// layout
//////////////////////////////////////////////////////////
.sec-cnt__060 {
	.inner {
		min-height: 1100px;
		@include media-breakpoint-down(xs) {
			min-height: 100px;
		}
	}
	.col-base__030 {
		position: absolute;
		@include media-breakpoint-down(xs) {
			position: static;
		}
		right: 0;
		.col-base__inner {
			position: relative;
			@include media-breakpoint-down(xs) {
			position: static;
		}
			.details {
				position: absolute;
				z-index: 1010;
				@include media-breakpoint-down(xs) {
			position: static;
		}
			}
			.img-base__040 {
				position: absolute;
				right: 60px;
				top: 370px;
				z-index: 1000;
				@include media-breakpoint-down(xs) {
			position: static;
		}
			}
		}
	}
	.btn-base__010 {
		position: absolute;

		bottom: 0;
		left: 0;
		right: 0;
		@include media-breakpoint-down(xs) {
			position: static;
		}
	}
}

.sec-cnt__070 {
	.inner {
		min-height: 1100px;
		@include media-breakpoint-down(xs) {
			min-height: 100px;
		}
	}
	.col-base__030 {
		position: absolute;
		@include media-breakpoint-down(xs) {
			position: static;
		}
		left: 0;
		.col-base__inner {
			position: relative;
			@include media-breakpoint-down(xs) {
				position: static;
			}
			.details {
				position: absolute;
				z-index: 1010;
				@include media-breakpoint-down(xs) {
				position: static;
			}
			}
			.img-base__040 {
				position: absolute;
				left: 60px;
				top: 370px;
				z-index: 1000;
				@include media-breakpoint-down(xs) {
					position: static;
				}
			}
		}
	}
	.btn-base__010 {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		@include media-breakpoint-down(xs) {
			position: static;
		}
	}
}

.sec-cnt__080 {
	.inner {
		min-height: 1100px;
		@include media-breakpoint-down(xs) {
			min-height: 100px;
		}
	}
	.col-base__030 {
		position: absolute;
		@include media-breakpoint-down(xs) {
			position: static;
		}
		right: 0;
		.col-base__inner {
			position: relative;
			@include media-breakpoint-down(xs) {
				position: static;
			}
			.details {
				position: absolute;
				z-index: 1010;
				@include media-breakpoint-down(xs) {
				position: static;
			}
			}
			.img-base__040 {
				position: absolute;
				right: 60px;
				top: 370px;
				z-index: 1000;
				@include media-breakpoint-down(xs) {
					position: static;
				}
			}
		}
	}
	.btn-base__010 {
		position: absolute;
		bottom: 0;
		@include media-breakpoint-down(xs) {
			position: static;
		}

	}
}


.finish {
	text-align: center;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
