// Basic
//////////////////////////////////////////////////////////

// compass
@import "lib/compass";


// Core
@import "variables";
@import "mixins";

// Normalize
@import "normalize";

// reboot
@import "reboot";

// Utility classes
@import "utilities";

// modaal classes
@import "modaal";

// swiper classes
@import "swiper";

// Lib
//////////////////////////////////////////////////////////




h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

body {
	background-color: #fffFFF;
	font-family: $font-family;
	font-size: $font-size;
	color: #000000;
	min-width: $base-width + 20px;
  	letter-spacing: 0.1px;
	line-height: 1.6;
	overflow-x: hidden;
	@include media-breakpoint-down(xs) {
		min-width: 100%;
		font-size: 12px;
	}
}


// Component
//////////////////////////////////////////////////////////
@import "component";


// Layout
//////////////////////////////////////////////////////////
@import "layout";

// adjust
//////////////////////////////////////////////////////////
@import "adjust";
